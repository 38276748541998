import CrudTable from '../../crud/CrudTable'
import {
    suscriptionService as service,
    carService,
    companyService,
} from '../../../services'
import FileDownloadIcon from '@mui/icons-material/PictureAsPdfOutlined'
import { Button } from '@mui/material'

const moment = require('moment')

const initFilter = {
    car: null,
    user: null,
    paymentState: null,
    from: null,
    to: null,
    rentalState: null,
    suscription: true,
}

export default function Suscription() {
    const createEstructureFilter = [
        {
            type: 'autocomplete',
            name: 'car',
            service: carService.combo,
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'company',
            service: companyService.combo,
            col: 4,
        },
    ]

    const paintCell = {
        start: (value, object) => {
            if (value) {
                return (
                    <>{moment(new Date(value)).format('DD/MM/YYYY HH:mm:ss')}</>
                )
            }
        },
        end: (value, object) => {
            if (value) {
                return (
                    <>{moment(new Date(value)).format('DD/MM/YYYY HH:mm:ss')}</>
                )
            }
        },
    }

    const conditionDelete = (rent) => {
        return false
    }
    return (
        <CrudTable
            service={service}
            urledit={'/suscription/edit/'}
            urlnew={'/suscription/new/'}
            filter={initFilter}
            hiddenButton={false}
            deleteaction={true}
            conditionDelete={conditionDelete}
            editable={true}
            paintCell={paintCell}
            rowsPerPage={30}
            i18n={'suscription'}
            exportfields={[
                'company.cif|company.name',
                'car.brand|car.model|car.number',
                'start',
                'end',
            ]}
            fieldstable={[
                'company.cif|company.name',
                'car.brand|car.model|car.number',
                'start',
                'end',
            ]}
            labels={{
                'company.cif|company.name': 'Compañía',
                'car.brand|car.model|car.number': 'Vehículo',
            }}
            estructureForm={createEstructureFilter}
        />
    )
}
