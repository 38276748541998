import CrudTable from '../../crud/CrudTable'
import {
    suscriptionrentService as service,
    carService,
    companyService,
    userService,
} from '../../../services'
import FileDownloadIcon from '@mui/icons-material/PictureAsPdfOutlined'
import { Button } from '@mui/material'

const moment = require('moment')

const initFilter = {
    car: null,
    user: null,
    paymentState: null,
    from: null,
    to: null,
    state: null,
}

export default function SuscriptionRent() {
    const createEstructureFilter = [
        {
            type: 'autocomplete',
            name: 'car',
            service: carService.combo,
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'company',
            service: companyService.combo,
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'user',
            service: userService.combo,
            col: 4,
        },
        {
            type: 'input',
            name: 'from',
            inputype: 'date',
            col: 4,
        },
        {
            type: 'input',
            name: 'to',
            inputype: 'date',
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'state',
            options: [
                {
                    key: 'PENDINGACCEPT',
                    name: 'Pendiente de aceptación',
                },
                {
                    key: 'ACCEPT',
                    name: 'Aceptado',
                },
                {
                    key: 'PROBLEMFIANCE',
                    name: 'Problemas con la fianza',
                },
                {
                    key: 'START',
                    name: 'Iniciado',
                },
                {
                    key: 'END',
                    name: 'Finalizado',
                },
            ],
        },
    ]

    const paintCell = {
        rentalState: (value, object) => {
            if (value == 'PENDINGACCEPT') {
                return (
                    <span style={{ color: 'black' }}>
                        Pendiente de aceptación
                    </span>
                )
            } else if (value == 'ACCEPT') {
                return <span style={{ color: 'black' }}>Aceptado</span>
            } else if (value == 'PROBLEMFIANCE') {
                return (
                    <span style={{ color: 'red' }}>Problema con la fianza</span>
                )
            } else if (value == 'START') {
                return <span style={{ color: 'orange' }}>Iniciado</span>
            } else if (value == 'END') {
                return <span style={{ color: 'orange' }}>Finalizado</span>
            } else {
                ;<></>
            }
        },
    }

    const conditionDelete = (rent) => {
        return false
    }
    return (
        <CrudTable
            service={service}
            urledit={'/suscription/rent/edit/'}
            urlnew={'/suscription/rent/new/'}
            filter={initFilter}
            hiddenButton={false}
            deleteaction={true}
            conditionDelete={conditionDelete}
            editable={true}
            paintCell={paintCell}
            rowsPerPage={30}
            i18n={'suscriptionrent'}
            exportfields={[
                'suscription.company.cif|suscription.company.name',
                'suscription.car.brand|suscription.car.model|suscription.car.number',
                'user.email|user.name|user.lastname',
                'start',
                'end',
                'km_start',
                'km_end',
                'km_real',
                'fuel_level_start',
                'fuel_level_end',
            ]}
            fieldstable={[
                'suscription.company.cif|suscription.company.name',
                'suscription.car.brand|suscription.car.model|suscription.car.number',
                'user.email|user.name|user.lastname',
                'rentalState',
                'start',
                'end',
                'km_start',
                'km_end',
                'km_real',
                'fuel_level_start',
                'fuel_level_end',
            ]}
            labels={{
                'suscription.company.cif|suscription.company.name': 'Compañía',
                'suscription.car.brand|suscription.car.model|suscription.car.number':
                    'Vehículo',
                'user.email|user.name|user.lastname': 'Usuario',
            }}
            estructureForm={createEstructureFilter}
        />
    )
}
