import CrudTable from '../../crud/CrudTable'
import {
    shareService as service,
    carService,
    userService,
    billService,
    companyService,
} from '../../../services'

import FileDownloadIcon from '@mui/icons-material/PictureAsPdf'
import { Button } from '@mui/material'

const moment = require('moment')

const initFilter = {
    car: null,
    user: null,
    paymentState: null,
    company: null,
    from: null,
    to: null,
}

export default function Share() {
    const createEstructureFilter = [
        {
            type: 'autocomplete',
            name: 'car',
            service: carService.combo,
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'user',
            service: userService.combo,
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'company',
            service: companyService.combo,
            col: 4,
        },
        {
            type: 'input',
            name: 'from',
            inputype: 'date',
            col: 4,
        },
        {
            type: 'input',
            name: 'from',
            inputype: 'to',
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'paymentState',
            options: [
                { key: 'PAYMENT', name: 'PAGADO' },
                { key: 'ERROR', name: 'ERROR EN EL PAGO' },
                { key: 'PARCIAL', name: 'PAGO PARCIAL' },
                { key: 'NONE', name: 'NO REALIZADO' },
            ],
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'rentalState',
            options: [
                { key: 'START', name: 'INICIADO' },
                { key: 'END', name: 'FINALIZADO' },
                { key: 'ISSUE', name: 'CON INCIDENCIA' },
                { key: 'CANCEL', name: 'CANCELADO' },
                { key: 'RESERVED', name: 'RESERVADO' },
                { key: 'CONFIRMRESERVED', name: 'RESERVA CONFIRMADA' },
                { key: 'ASSIGNCAR', name: 'COCHE ASIGNADO' },
                { key: 'NOTCANCEL', name: 'NO CANCELADOS' },
            ],
            col: 4,
        },
    ]

    const paintCell = {
        seguro: (value, object) => {
            return value ? (
                <>
                    <img src="/ic_secbig.png" style={{ width: '30px' }} />
                    <br />
                </>
            ) : (
                <></>
            )
        },
        km_real: (value, object) => {
            return <>{value ? value.toFixed(2) : 0} Km</>
        },
        paymentState: (value, object) => {
            if (value == 'NONE') {
                return <span style={{ color: 'black' }}>No realizado</span>
            } else if (value == 'PAYMENT') {
                return <span style={{ color: 'green' }}>Pagado</span>
            } else if (value == 'PARCIAL') {
                return <span style={{ color: 'orange' }}>Pago parcial</span>
            } else if (value == 'ERROR') {
                return <span style={{ color: 'red' }}>Error en el pago</span>
            } else {
                ;<></>
            }
        },
        start: (value, object) => {
            if (value) {
                return (
                    <>{moment(new Date(value)).format('DD/MM/YYYY HH:mm:ss')}</>
                )
            }
        },
        end: (value, object) => {
            if (value) {
                return (
                    <>{moment(new Date(value)).format('DD/MM/YYYY HH:mm:ss')}</>
                )
            }
        },
        rentalState: (value, object) => {
            if (value == 'RESERVED') {
                return <span style={{ color: 'black' }}>Reservado</span>
            } else if (value == 'CONFIRMRESERVED') {
                return (
                    <span style={{ color: 'black' }}>Reserva confirmada</span>
                )
            } else if (value == 'ASSIGNCAR') {
                return <span style={{ color: 'black' }}>Coche asignado</span>
            } else if (value == 'START') {
                return <span style={{ color: 'green' }}>Iniciado</span>
            } else if (value == 'END') {
                return <span style={{ color: 'green' }}>Finalizado</span>
            } else if (value == 'ISSUE') {
                return <span style={{ color: 'red' }}>Con incidencia</span>
            } else if (value == 'CANCEL') {
                return <span style={{ color: 'orange' }}>Cancelado</span>
            } else {
                ;<></>
            }
        },
        billed: (value, object) => {
            if (object['idBill']) {
                return (
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: '#83c63b',
                            color: 'white',
                        }}
                        onClick={() => {
                            billService.print(
                                object['idBill'],
                                'Factura-Share-' + object['id'] + '.pdf',
                                (data, error) => {}
                            )
                        }}
                    >
                        {' '}
                        <FileDownloadIcon />
                    </Button>
                )
            } else {
                return <></>
            }
        },
    }

    const conditionDelete = (share) => {
        var isGenerate =
            share &&
            share.id &&
            share.rentalState != '' &&
            share.rentalState == 'CANCEL'
        return isGenerate
    }

    return (
        <CrudTable
            service={service}
            urledit={'/share/edit/'}
            urlnew={'/share/new/'}
            filter={initFilter}
            hiddenButton={true}
            rowsPerPage={30}
            editable={true}
            paintCell={paintCell}
            deleteaction={true}
            conditionDelete={conditionDelete}
            i18n={'share'}
            hiddencol={true}
            keytable={'share'}
            exportfields={[
                'user.email',
                'user.name',
                'user.lastname',
                'user.nif',
                'car.number',
                'start',
                'end',
                'km_real',
                'min_standby',
                'paymentState',
                'total',
            ]}
            fieldstable={[
                'user.email|user.name|user.lastname',
                'company.name',
                'car.brand|car.model|car.number',
                'seguro',
                'start',
                'end',
                'rentalState',
                'paymentState',
                'total',
                'km_real',
                'drivers.name',
                'billed',
            ]}
            labels={{
                'user.email|user.name|user.lastname': 'Conductor',
                'car.brand|car.model|car.number': 'Vehículo',
                seguro: '',
            }}
            estructureForm={createEstructureFilter}
        />
    )
}
