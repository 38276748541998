import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../crud/CrudForm'
import { Link } from 'react-router-dom'
import {
    suscriptionrentService as service,
    suscriptionService,
    companyService,
    userService,
    carService,
} from '../../../services'
import { useTranslation } from 'react-i18next'
import {
    Box,
    Button,
    Modal,
    Autocomplete,
    TextField,
    Grid,
} from '@mui/material'
import { ToastContainer, toast } from 'react-toastify'
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps'
import { ControlRouteMap } from '../../utils/ControlRouteMap'
import Suscription from './Suscription'

const initElement = {
    user: { id: -1, name: '' },
    suscription: { id: -1, name: '' },
    end: '',
    start: null,
    end_real: null,
    start_real: null,
    rentalState: null,
    km_start: null,
    km_end: null,
    km_real: null,
    fuel_level_start: null,
    fuel_level_end: null,
    tokenPaymentFiance: '',
    route: '',
}

const initElementLine = {
    concept: '',
    price: 0.0,
    ammount: 0.0,
    total: 0.0,
}

export default function FormSuscriptionrent() {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        maxWidth: '80%',
        bgcolor: 'white',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }

    type FormParms = {
        id: string
    }
    let { id } = useParams<FormParms>()
    const { t, i18n } = useTranslation()

    var stateForm = {
        openModalConfirmFree: false,
        openModalPay: false,
        money: 0,
        motive: '',
    }

    const fieldmap = (field, valueform, objectform, setSate, setValueField) => {
        if (
            objectform['id'] &&
            objectform['id'] > 0 &&
            objectform['lng_point_start'] &&
            objectform['lat_point_start']
        ) {
            return (
                <APIProvider apiKey={process.env.REACT_APP_GMAPS!}>
                    <Map
                        center={{
                            lat: objectform['lat_point_start'],
                            lng: objectform['lng_point_start'],
                        }}
                        zoom={18}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                        styles={[
                            {
                                featureType: 'poi',
                                stylers: [{ visibility: 'off' }],
                            },
                        ]}
                    >
                        <Marker
                            position={{
                                lat: objectform['lat_point_start'],
                                lng: objectform['lng_point_start'],
                            }}
                            title={objectform['id'] + 'start'}
                            icon={'/marker.png'}
                        />

                        {objectform['lat_point_end'] &&
                            objectform['lng_point_end'] && (
                                <Marker
                                    position={{
                                        lat: objectform['lat_point_end'],
                                        lng: objectform['lng_point_end'],
                                    }}
                                    title={objectform['id'] + 'end'}
                                    icon={'/markerFree.png'}
                                />
                            )}
                    </Map>

                    <ControlRouteMap route={objectform['routeUser']} />
                </APIProvider>
            )
        } else {
            return <></>
        }
    }

    const estructureform = [
        {
            type: 'autocomplete',
            name: 'suscription.id',
            service: suscriptionService.combo,
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'user.id',
            service: userService.combo,
            col: 4,
        },
        {
            type: 'input',
            name: 'start',
            inputtype: 'datetime-local',
            col: 3,
        },
        {
            type: 'input',
            name: 'end',
            inputtype: 'datetime-local',
            col: 3,
        },
        {
            type: 'autocomplete',
            name: 'rentalState',
            options: [
                {
                    key: 'PENDINGACCEPT',
                    name: 'Pendiente de aceptación',
                },
                {
                    key: 'ACCEPT',
                    name: 'Aceptado',
                },
                {
                    key: 'PROBLEMFIANCE',
                    name: 'Problemas con la fianza',
                },
                {
                    key: 'START',
                    name: 'Iniciado',
                },
                {
                    key: 'END',
                    name: 'Finalizado',
                },
            ],
            readonly: true,
        },
        {
            type: 'title',
            name: 'other',
            col: 12,
        },
        {
            type: 'input',
            name: 'start_real',
            inputtype: 'datetime-local',
            col: 3,
            readonly: true,
        },
        {
            type: 'input',
            name: 'end_real',
            inputtype: 'datetime-local',
            col: 3,
            readonly: true,
        },
        {
            type: 'input',
            name: 'km_start',
            col: 3,
            readonly: true,
        },
        {
            type: 'input',
            name: 'km_end',
            col: 3,
            readonly: true,
        },
        {
            type: 'input',
            name: 'km_real',
            col: 3,
            readonly: true,
        },
        {
            type: 'input',
            name: 'fuel_level_start',
            col: 3,
            readonly: true,
        },
        {
            type: 'input',
            name: 'fuel_level_end',
            col: 3,
            readonly: true,
        },
        {
            type: 'title',
            name: 'fiancetitle',
            col: 12,
        },
        {
            type: 'input',
            name: 'tokenPaymentFiance',
            col: 3,
        },
        {
            type: 'checkbox',
            name: 'freeFiance',
            readonly: true,
            col: 3,
        },
        {
            type: 'input',
            name: 'amountFiance',
            readonly: true,
            col: 3,
        },
        {
            type: 'input',
            name: 'motiveFiance',
            readonly: true,
            col: 3,
        },
        {
            type: 'title',
            name: 'maptitle',
            col: 12,
        },
        {
            type: 'custom',
            name: 'route',
            custom: fieldmap,
            col: 4,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        objectedit['car'] =
            element['car'] && element['car']['id']
                ? element['car']
                : { id: -1, name: '' }
        objectedit['company'] =
            element['company'] && element['company']['id']
                ? element['company']
                : { id: -1, name: '' }

        objectedit['start'] = element['start'] ? element['start'] : ''
        objectedit['end'] = element['end'] ? element['end'] : ''

        return objectedit
    }

    const isValid = function (object) {
        debugger
        if (!object['suscription'] || !object['suscription']['id']) {
            return t('suscriptionrent.error.suscription')
        }
        if (!object['user'] || !object['user']['id']) {
            return t('suscriptionrent.error.company')
        }
        if (!object['start'] || !object['start']) {
            return t('suscriptionrent.error.start')
        }
        if (!object['end'] || !object['end']) {
            return t('suscriptionrent.error.end')
        }

        return null
    }

    const changeState = function (state) {
        service.changestate(id, state, (data, error) => {
            if (data != null && data.data) {
                window.location.reload()
            }
        })
    }

    const lockFiance = function () {
        service.lockFiance(id, (data, error) => {
            if (data != null && data.data) {
                window.location.reload()
            }
        })
    }

    const moreActions = (getObject, state, saveState) => {
        var isGenerate = id
        var object = getObject()
        var start =
            object['rentalState'] == null ||
            (object['rentalState'] != 'START' && object['rentalState'] != 'END')
        var end =
            object['rentalState'] != null && object['rentalState'] == 'START'
        var payfiance =
            object['tokenPaymentFiance'] == null ||
            object['tokenPaymentFiance'] == ''

        var freefiance =
            !payfiance &&
            (object['freeFiance'] == null || !object['freeFiance'])

        return (
            <>
                {isGenerate && start && (
                    <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: '30px' }}
                        onClick={() => {
                            changeState('START')
                        }}
                    >
                        {' '}
                        INICIAR RENT
                    </Button>
                )}
                {isGenerate && end && (
                    <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: '30px' }}
                        onClick={() => {
                            changeState('END')
                        }}
                    >
                        {' '}
                        FINALIZAR RENT
                    </Button>
                )}
                {isGenerate && payfiance && (
                    <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: '30px' }}
                        onClick={() => {
                            lockFiance()
                        }}
                    >
                        {' '}
                        BLOQUEAR FIANZA
                    </Button>
                )}
                {isGenerate && freefiance && (
                    <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: '30px' }}
                        onClick={() => {
                            setStateInParent(
                                'openModalConfirmFree',
                                true,
                                saveState
                            )
                        }}
                    >
                        {' '}
                        LIBERAR FIANZA
                    </Button>
                )}
                {isGenerate && freefiance && (
                    <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: '30px' }}
                        onClick={() => {
                            setStateInParent(
                                'openModalConfirmPay',
                                true,
                                saveState
                            )
                        }}
                    >
                        {' '}
                        REALIZAR COBRO DE LA FIANZA
                    </Button>
                )}
            </>
        )
    }

    const setStateInParent = (name, val, call) => {
        if (val) {
            call(name, val)
        } else {
            call(name, val)
        }
    }

    const initeffect = (saveState) => {}

    const sendPayFiance = (money, motive) => {
        var dataToSend = {
            amountFiance: money,
            motiveFiance: motive,
        }

        service.payfiance(id, dataToSend, (data, error) => {
            if (data && data.data) {
                window.location.reload()
            } else {
                toast.error(error.mes, {
                    position: toast.POSITION.TOP_CENTER,
                })
            }
        })
    }

    const sendFreeFiance = () => {
        service.fianceFree(id, (data, error) => {
            if (data && data.data) {
                window.location.reload()
            } else {
                toast.error(error.mes, {
                    position: toast.POSITION.TOP_CENTER,
                })
            }
        })
    }

    return (
        <>
            <CrudForm
                objectform={{ ...initElement }}
                estructureform={estructureform}
                i18n={'suscriptionrent'}
                urledit={'/suscription/rent/edit/'}
                urlCancel={'/suscription/rent'}
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
                moreActions={moreActions}
                initeffect={initeffect}
                stateform={stateForm}
                haveInside={(state, saveState) => {
                    return (
                        <>
                            <Modal
                                open={state.openModalConfirmFree}
                                onClose={() =>
                                    setStateInParent(
                                        'openModalConfirmFree',
                                        false,
                                        saveState
                                    )
                                }
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                            >
                                <Box sx={style}>
                                    <div style={{ marginBottom: '30px' }}>
                                        ¿Quieres liberar la fianza? ¿Esta todo
                                        correcto y no habra que reclamar nada al
                                        cliente?
                                    </div>
                                    <div>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            style={{ marginRight: '30px' }}
                                            onClick={() =>
                                                setStateInParent(
                                                    'openModalConfirmFree',
                                                    false,
                                                    saveState
                                                )
                                            }
                                        >
                                            {' '}
                                            Cancelar
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                sendFreeFiance()
                                            }}
                                        >
                                            {' '}
                                            Liberar{' '}
                                        </Button>
                                    </div>
                                </Box>
                            </Modal>
                            <Modal
                                open={state.openModalConfirmPay}
                                onClose={() =>
                                    setStateInParent(
                                        'openModalConfirmPay',
                                        false,
                                        saveState
                                    )
                                }
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                            >
                                <Box sx={style}>
                                    <div style={{ marginBottom: '30px' }}>
                                        ¿Deseas cobrar parte de la finza? Indica
                                        la cantidad y el motivo
                                    </div>
                                    <div style={{ marginBottom: '30px' }}>
                                        <TextField
                                            variant="standard"
                                            sx={{ width: '100%' }}
                                            id={'motive'}
                                            name={'motive'}
                                            label={'Motivo'}
                                            value={state.motive}
                                            onChange={(evt) => {
                                                setStateInParent(
                                                    'motive',
                                                    evt.target.value,
                                                    saveState
                                                )
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div style={{ marginBottom: '30px' }}>
                                        <TextField
                                            variant="standard"
                                            sx={{ width: '100%' }}
                                            id={'money'}
                                            name={'money'}
                                            label={'Cantidad'}
                                            value={state.money}
                                            onChange={(evt) => {
                                                setStateInParent(
                                                    'money',
                                                    evt.target.value,
                                                    saveState
                                                )
                                            }}
                                            type={'number'}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            style={{ marginRight: '30px' }}
                                            onClick={() =>
                                                setStateInParent(
                                                    'openModalConfirmPay',
                                                    false,
                                                    saveState
                                                )
                                            }
                                        >
                                            {' '}
                                            Cancelar
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                sendPayFiance(
                                                    state['money'],
                                                    state['motive']
                                                )
                                            }}
                                        >
                                            {' '}
                                            Cobrar{' '}
                                        </Button>
                                    </div>
                                </Box>
                            </Modal>
                        </>
                    )
                }}
            />
        </>
    )
}
