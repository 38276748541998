import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../crud/CrudForm'
import { companyService as service } from '../../../services'
import { useTranslation } from 'react-i18next'
import TabPrices from './TabPrices'
import TabTimetable from './TabTimetable'
import TabDisabledays from './TabDisabledays'
import TabUsersCompany from './TabUsers'
import CrudFormStateTabs from '../../crud/CrudFormStateTabs'

const initElement = {
    code: '',
    name: '',
    picture: '',
    cif: '',
    phone: '',
    email: '',
    baseEmail: '',
    usersMail: '',
    street: '',
    city: '',
    state: '',
    postalcode: '',
}

export default function FormCompany() {
    const [objectform, setObjectform] = React.useState({ ...initElement })
    type FormParms = {
        id: string
    }
    let { id } = useParams<FormParms>()
    const [idRow, setIdrow] = useState(id ? Number(id) : 0)
    const { t, i18n } = useTranslation()

    const estructureform = [
        {
            type: 'input',
            name: 'code',
            col: 4,
        },
        {
            type: 'input',
            name: 'cif',
            col: 4,
        },
        {
            type: 'input',
            name: 'name',
            col: 4,
        },
        {
            type: 'input',
            name: 'phone',
            col: 4,
        },
        {
            type: 'input',
            name: 'email',
            col: 4,
        },
        {
            type: 'image',
            name: 'picture',
            col: 2,
        },
        {
            type: 'title',
            name: 'titledir',
            col: 4,
        },
        {
            type: 'input',
            name: 'street',
            col: 8,
        },
        {
            type: 'input',
            name: 'city',
            col: 4,
        },
        {
            type: 'input',
            name: 'postalcode',
            col: 4,
        },
        {
            type: 'input',
            name: 'state',
            col: 4,
        },
        {
            type: 'title',
            name: 'titleconfig',
            col: 12,
        },
        {
            type: 'input',
            name: 'maxMoneyMonthShare',
            inputtype: 'number',
            step: 0.01,
            col: 4,
        },
        {
            type: 'checkbox',
            name: 'validToCompany',
            col: 4,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        objectedit['name'] = element.name ? element.name : ''
        return objectedit
    }

    const isValid = function (object) {
        if (!object['name']) {
            return t('company.error.name')
        }
        if (!object['code']) {
            return t('company.error.name')
        }

        return null
    }

    const tabPrices = (id) => {
        return <TabPrices idcompany={id} />
    }

    const tabTabTimetable = (id) => {
        return <TabTimetable idcompany={id} />
    }

    const tabTabDisabledays = (id) => {
        return <TabDisabledays idcompany={id} />
    }
    const tabTabUsers = (id) => {
        return <TabUsersCompany idcompany={id} />
    }

    return (
        <>
            <CrudFormStateTabs
                objectform={{ ...initElement }}
                estructureform={estructureform}
                i18n={'company'}
                urledit={'/company/edit/'}
                urlCancel={'/company'}
                service={service}
                recoveryElement={recoveryElement}
                tabs={[
                    { title: 'company.tabs.prices', method: tabPrices },
                    {
                        title: 'company.tabs.timetable',
                        method: tabTabTimetable,
                    },
                    {
                        title: 'company.tabs.user',
                        method: tabTabUsers,
                    },
                ]}
                valid={isValid}
            />
        </>
    )
}
