import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../crud/CrudForm'
import { Link } from 'react-router-dom'
import {
    suscriptionService as service,
    companyService,
    carService,
} from '../../../services'
import { useTranslation } from 'react-i18next'
import {
    Box,
    Button,
    Modal,
    Autocomplete,
    TextField,
    Grid,
} from '@mui/material'
import { ToastContainer, toast } from 'react-toastify'
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps'
import { ControlRouteMap } from '../../utils/ControlRouteMap'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import CheckIcon from '@mui/icons-material/Check'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import moment from 'moment'

const initElement = {
    car: { id: -1, name: '' },
    company: { id: -1, name: '' },
    end: '',
    start: '',
}

const initElementLine = {
    concept: '',
    price: 0.0,
    ammount: 0.0,
    total: 0.0,
}

export default function FormSuscription() {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        maxWidth: '80%',
        bgcolor: 'white',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }

    type FormParms = {
        id: string
    }
    let { id } = useParams<FormParms>()
    const { t, i18n } = useTranslation()

    const estructureform = [
        {
            type: 'autocomplete',
            name: 'company.id',
            service: companyService.combo,
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'car.id',
            service: carService.combo,
            col: 4,
        },
        {
            type: 'input',
            name: 'start',
            inputtype: 'datetime-local',
            col: 3,
        },
        {
            type: 'input',
            name: 'end',
            inputtype: 'datetime-local',
            col: 3,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        objectedit['car'] =
            element['car'] && element['car']['id']
                ? element['car']
                : { id: -1, name: '' }
        objectedit['company'] =
            element['company'] && element['company']['id']
                ? element['company']
                : { id: -1, name: '' }

        objectedit['start'] = element['start'] ? element['start'] : ''
        objectedit['end'] = element['end'] ? element['end'] : ''

        return objectedit
    }

    const isValid = function (object) {
        if (!object['car'] || !object['car']['id']) {
            return t('suscription.error.car')
        }
        if (!object['company'] || !object['company']['id']) {
            return t('suscription.error.company')
        }
        if (!object['start'] || !object['start']) {
            return t('suscription.error.start')
        }

        return null
    }

    const moreActions = (getObject, state, saveState) => {
        return <></>
    }

    const initeffect = (saveState) => {}

    return (
        <>
            <CrudForm
                objectform={{ ...initElement }}
                estructureform={estructureform}
                i18n={'suscription'}
                urledit={'/suscription/edit/'}
                urlCancel={'/suscription'}
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
                moreActions={moreActions}
                initeffect={initeffect}
            />
        </>
    )
}
