import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import CustomPaginationActionsTable from '../table/Table'
import Filter from '../filter/Filter'
import { Link } from 'react-router-dom'
import Layout from '../layout/Layout'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faFileExcel,
    faFilePdf,
    faFileWord,
} from '@fortawesome/free-solid-svg-icons'

let countS = 0

export default function CrudTable(props) {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()

    const initNullable = () => {
        var init: any = null

        return init
    }

    const createEmpty = () => {
        var ar: any[] = []

        return ar
    }

    const initLabelHeader = () => {
        var fields = props.exportfields ? props.exportfields : props.fieldstable

        var header: any[] = []

        fields.forEach((element) => {
            header.push(
                props.labels && props.labels[element]
                    ? props.labels[element]
                    : t(props.i18n + '.' + 'field.' + element)
            )
        })

        return header
    }

    const [
        {
            page,
            rowsPerPage,
            filter,
            haveother,
            othersPanel,
            selectable,
            columOrder,
            columOrderDirection,
            expendedFilter,
            rows,
            rowsTotal,
            exportbtn,
            exportfields,
            exportlabels,
            exportexcel,
            exportword,
            exportpdf,
            loading,
        },
        setState,
    ] = useState({
        page: 0,
        rowsPerPage: props.rowsPerPage ? props.rowsPerPage : 10,
        filter: props.filter ? props.filter : {},
        haveother: props.haveother ? props.haveother : false,
        othersPanel: props.othersPanel ? props.othersPanel : null,
        selectable: props.selectable ? props.selectable : false,
        columOrder: initNullable(),
        columOrderDirection: initNullable(),
        expendedFilter: props.expendedFilter ? true : false,
        rows: createEmpty(),
        rowsTotal: 0,
        exportbtn: props.notexport ? false : true,
        exportfields: props.exportfields
            ? props.exportfields
            : props.fieldstable,
        exportlabels: props.exportlabels
            ? props.exportlabels
            : initLabelHeader(),
        exportexcel: 0,
        exportword: 0,
        exportpdf: 0,
        loading: props.expendedFilter ? false : true,
    })

    const fieldstable = props.fieldstable ? props.fieldstable : []
    const service = props.service ? props.service : null
    const estructureForm = props.estructureForm ? props.estructureForm : null
    const urledit = props.urledit ? props.urledit : ''
    const urlnew = props.urlnew ? props.urlnew : ''
    const fieldId = props.fieldId ? props.fieldId : 'id'
    const hiddenFilter = props.hiddenFilter ? props.hiddenFilter : false
    const editable = props.editable != null ? props.editable : true

    const changePageTable = (page, size) => {
        var update = {
            page: page,
            rowsPerPage: size,
            filter: { ...filter },
            loading: true,
        }
        setState((currentState) => ({ ...currentState, ...update }))

        if (props.setFilter) {
            props.setFilter({ ...filter })
        }
    }

    const cleanFilter = (filter) => {
        console.log(filter)
        var update = {
            page: 0,
            rowsPerPage: rowsPerPage,
            filter: { ...filter },
            loading: true,
        }
        setState((currentState) => ({ ...currentState, ...update }))

        if (props.setFilter) {
            props.setFilter({ ...filter })
        }
    }

    const searchFilter = (filter) => {
        console.log(filter)
        var update = {
            page: 0,
            rowsPerPage: rowsPerPage,
            filter: { ...filter },
            loading: true,
        }
        setState((currentState) => ({ ...currentState, ...update }))

        if (props.setFilter) {
            props.setFilter({ ...filter })
        }
        //search(0,rowsPerPage);
    }

    const search = (page, size, filter) => {
        filter['columOrder'] = columOrder
        filter['asc'] = columOrderDirection

        setState((currentState) => ({ ...currentState, ['filter']: filter }))
        service.pageFilter(page, size, filter, (data, error) => {
            if (data) {
                console.log(data)
                var update = {
                    rows: data.data,
                    rowsTotal: data.total,
                    loading: false,
                }
                setState((currentState) => ({ ...currentState, ...update }))
            } else {
                var update2 = {
                    rows: [],
                    rowsTotal: 0,
                    loading: false,
                }
                setState((currentState) => ({ ...currentState, ...update2 }))
            }
        })
    }

    useEffect(() => {
        countS = 0
        if (!expendedFilter) {
            search(0, props.rowsPerPage ? props.rowsPerPage : 10, filter)
            countS = 1
        }
    }, [])

    useEffect(() => {
        if (countS > 0) {
            search(page, rowsPerPage, filter)
        }
        countS = countS + 1
    }, [page, rowsPerPage, filter])

    const deleteHandler = (id) => {
        service.removeLogic(id, (data, error) => {
            if (data) {
                console.log(data)
                search(page, rowsPerPage, filter)
            }
        })
    }

    const eventSelect = (id) => {
        if (editable) {
            if (props.gotoEdit) {
                rows.forEach((element) => {
                    if (element[fieldId] == id) {
                        props.gotoEdit(element)
                    }
                })
            } else {
                navigate(urledit + id)
            }
        }
    }

    const clickHeader = (column) => {
        if (column && column == columOrder) {
            if (columOrderDirection) {
                var update = {
                    columOrderDirection: null,
                    columOrder: null,
                }
                setState((currentState) => ({ ...currentState, ...update }))
            } else {
                var update2 = {
                    columOrderDirection: true,
                }
                setState((currentState) => ({ ...currentState, ...update2 }))
            }
        } else {
            var update3 = {
                columOrderDirection: false,
                columOrder: column,
            }
            setState((currentState) => ({ ...currentState, ...update3 }))
        }
    }

    const clickExportExcel = () => {
        setState((currentState) => ({
            ...currentState,
            ['exportexcel']: currentState['exportexcel'] + 1,
        }))
    }

    const clickExportWord = () => {
        setState((currentState) => ({
            ...currentState,
            ['exportword']: currentState['exportword'] + 1,
        }))
    }

    const clickExportPdf = () => {
        setState((currentState) => ({
            ...currentState,
            ['exportpdf']: currentState['exportpdf'] + 1,
        }))
    }

    useEffect(() => {
        console.log('COLUMN', columOrderDirection, 'SIZE', rows.length)
        if (columOrderDirection != null || rows.length > 0) {
            var filterAux = { ...filter }
            filterAux['columOrder'] = columOrder
            filterAux['asc'] = columOrderDirection
            var update = {
                page: 0,
                rowsPerPage: rowsPerPage,
                filter: filterAux,
            }
            setState((currentState) => ({ ...currentState, ...update }))
        }
    }, [columOrder, columOrderDirection])

    useEffect(() => {
        if (exportexcel > 0) {
            setState((currentState) => ({
                ...currentState,
                ['exportexcel']: 0,
            }))

            var bodyrequest = {
                filter: filter,
                headers: exportlabels,
                fields: exportfields,
                header: false,
                title: t(props.i18n + '.title'),
            }

            service.allFilterExcel(bodyrequest, (data, error) => {})
        }
    }, [exportexcel, filter, exportfields, exportlabels])

    useEffect(() => {
        if (exportword > 0) {
            setState((currentState) => ({ ...currentState, ['exportword']: 0 }))

            var bodyrequest = {
                filter: filter,
                headers: exportlabels,
                fields: exportfields,
                header: false,
                title: t(props.i18n + '.title'),
            }

            service.allFilterWord(bodyrequest, (data, error) => {})
        }
    }, [exportword, filter, exportfields, exportlabels])

    useEffect(() => {
        if (exportpdf > 0) {
            setState((currentState) => ({ ...currentState, ['exportpdf']: 0 }))

            var bodyrequest = {
                filter: filter,
                headers: exportlabels,
                fields: exportfields,
                header: false,
                title: t(props.i18n + '.title'),
            }

            service.allFilterPdf(bodyrequest, (data, error) => {})
        }
    }, [exportpdf, filter, exportfields, exportlabels])

    useEffect(() => {
        return () => {
            setState({
                page: 0,
                rowsPerPage: 10,
                filter: {},
                haveother: false,
                othersPanel: null,
                selectable: false,
                columOrder: initNullable(),
                columOrderDirection: initNullable(),
                expendedFilter: false,
                rows: createEmpty(),
                rowsTotal: 0,
                exportbtn: false,
                exportfields: [],
                exportlabels: [],
                exportexcel: 0,
                exportword: 0,
                exportpdf: 0,
                loading: false,
            })
        }
    }, [])

    return (
        <Layout
            t={t}
            title={props.title ? props.title : t(props.i18n + '.title')}
        >
            <DivPanel>
                {!hiddenFilter && (
                    <Filter
                        t={t}
                        i18n={props.i18n}
                        cleanFilter={cleanFilter}
                        searchFilter={searchFilter}
                        filter={filter}
                        titlebutton={t(props.i18n + '.titlebutton')}
                        estructure={estructureForm}
                        urlnew={urlnew}
                        hiddenButton={
                            props.hiddenButton ? props.hiddenButton : false
                        }
                        expanded={expendedFilter}
                    />
                )}

                <DivTable>
                    {hiddenFilter && !props.hiddenew && (
                        <Grid
                            item
                            xs={12}
                            style={{ textAlign: 'right', marginBottom: '10px' }}
                        >
                            <Button
                                component={Link}
                                to={props.urlnew ? props.urlnew : '#'}
                                variant="contained"
                                color="secondary"
                                style={{ marginLeft: '30px' }}
                            >
                                {' '}
                                {t(props.i18n + '.titlebutton')}{' '}
                            </Button>
                        </Grid>
                    )}
                    {haveother && othersPanel}
                    {exportbtn && (
                        <Grid
                            item
                            xs={12}
                            style={{ textAlign: 'right', marginBottom: '10px' }}
                        >
                            <Button
                                variant="text"
                                color="secondary"
                                style={{ marginLeft: '5px', fontSize: '30px' }}
                                onClick={clickExportExcel}
                            >
                                {' '}
                                <FontAwesomeIcon icon={faFileExcel} />{' '}
                            </Button>
                            <Button
                                variant="text"
                                color="secondary"
                                style={{
                                    marginLeft: '5px',
                                    fontSize: '30px',
                                    color: 'red',
                                }}
                                onClick={clickExportPdf}
                            >
                                {' '}
                                <FontAwesomeIcon icon={faFilePdf} />{' '}
                            </Button>
                            <Button
                                variant="text"
                                color="secondary"
                                style={{
                                    marginLeft: '5px',
                                    fontSize: '30px',
                                    color: 'blue',
                                }}
                                onClick={clickExportWord}
                            >
                                {' '}
                                <FontAwesomeIcon icon={faFileWord} />{' '}
                            </Button>
                        </Grid>
                    )}

                    {loading && (
                        <div style={{ textAlign: 'center' }}>
                            <img
                                src="/load2.gif"
                                alt="load"
                                style={{ width: '200px' }}
                            />
                        </div>
                    )}
                    {!loading && (
                        <CustomPaginationActionsTable
                            t={t}
                            i18n={props.i18n}
                            labels={props.labels ? props.labels : []}
                            rows={rows}
                            fields={fieldstable}
                            fieldId={fieldId}
                            rowsPerPage={rowsPerPage}
                            rowsTotal={rowsTotal}
                            changePage={changePageTable}
                            page={page}
                            deleteaction={
                                props.deleteaction != null
                                    ? props.deleteaction
                                    : true
                            }
                            deleteactionHandler={deleteHandler}
                            selectHandler={eventSelect}
                            getDeleteButton={
                                props.getDeleteButton
                                    ? props.getDeleteButton
                                    : null
                            }
                            getEditButton={
                                props.getEditButton ? props.getEditButton : null
                            }
                            editable={editable}
                            defaultValues={
                                props.defaultValues ? props.defaultValues : {}
                            }
                            paintCell={props.paintCell ? props.paintCell : {}}
                            paintvalue={
                                props.paintvalue ? props.paintvalue : {}
                            }
                            moreActions={
                                props.moreActions ? props.moreActions : []
                            }
                            selectable={
                                props.selectable ? props.selectable : false
                            }
                            handlerSelected={
                                props.handlerSelected
                                    ? props.handlerSelected
                                    : null
                            }
                            conditionDelete={
                                props.conditionDelete
                                    ? props.conditionDelete
                                    : null
                            }
                            columOrder={columOrder}
                            columOrderDirection={columOrderDirection}
                            clickHeader={props.order ? clickHeader : null}
                            orderColumns={props.orderColumns}
                            hiddencol={props.hiddencol}
                            keytable={props.keytable}
                            conditionColor={
                                props.conditionColor
                                    ? props.conditionColor
                                    : null
                            }
                        />
                    )}
                </DivTable>

                {props.children}
            </DivPanel>
        </Layout>
    )
}

const DivPanel = styled.div`
    padding: 2em;
    @media (max-width: 767px) {
        padding: 0.5em;
    }
`

const DivTable = styled.div``
